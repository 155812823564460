.cameraContainer {
  overflow: hidden;
}

.header {

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;

  padding: 4px;

  color: var(--first-font-color);
  background-color: var(--header-button-color);
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: var(--first-font-color);
  margin-top: 4px;
}

.content {
  position: relative;
}

.imageContainer {
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 100%;
  transform: translateZ(0);
  border-radius: 0px 0px 4px 4px;
}

.selected {
  background-color: var(--header-button-color);
  font-weight: bold;
}

.small {
  width: 80% !important;
  border-radius: 4px;
}

.hidden {
  display: none;
}

.dataLoader {
  margin: 0 auto;
  path {
    fill: var(--second-font-color);
  }
}
