.shortcut {
  max-width: 40px;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  gap: 10px;

  padding: 34px 0 10px 0;
}

.top {

}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  gap: 10px;
}

.bottom {

}
