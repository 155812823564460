.element {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    background: rgb(10,9,8);
    background: linear-gradient(90deg, rgba(10,9,8,1) 0%, rgba(62,140,238,1) 100%);
}

.name {
    font-size: 16px;
    color: var(--white);
    font-weight: bold;
}
