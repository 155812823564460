.navigationElement {
    height: 35px;
    width: 35px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}
.pending {

}
.active {

}
.transitioning {

}
.icon {

}
.title {
    font-size: 10px;
    color: var(--first-font-color);
    text-decoration: none;
}

.navigationElement.active .title {
    font-weight: bold;
}
