.selector {
  width: 240px;
  height: 70px;

  border-radius: 12px;
  background-color: var(--second-bg-color);

  position: relative;

  user-select: none;
}

.selectorDefaultContainer {
  display: flex;
  align-items: center;

  padding: 10px 20px 10px 10px;

  position: relative;
}

.pointerCursor {
  cursor: pointer;
}

.iconContainer {
  height: 50px;
  width: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 6px;

  background-color: var(--third-bg-color);
}

.icon {
  height: 44px;
  width: 44px;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;

  padding-left: 10px;
  height: 100%;
}

.descriptionTitle {
  font-size: 16px;
  font-weight: bold;
  text-overflow: ellipsis;
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
}

.descriptionSubTitle {
  font-size: 12px;
}

.arrowContainer {
  position: absolute;

  right: 8px;
}

.arrow {

  transition: .2s;
}


/* Container of systems */
.selectorContainer {
  position: absolute;

  width: 220px;

  background-color: var(--second-bg-color);

  left: 0;
  right: 0;

  margin: auto;

  border-radius: 12px;

  display: none;
  flex-direction: column;

  overflow: hidden;

  cursor: default;
  z-index: 101;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.toTopContainer {
  top: 85px;
}

.toBottomContainer {
  bottom: 85px;
}

.arrowFilledContainer {
  position: absolute;

  left: 0;
  right: 0;

  margin: auto;

  justify-content: center;

  display: none;

  cursor: default;

  z-index: 100;
}

.toTopArrow {
  transform: rotate(0deg);
  top: 74px;
}

.toBottomArrow {
  transform: rotate(180deg);
  bottom: 74px;
}

.currentSelected {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 16px 10px;

  box-sizing: border-box;
  width: 100%;

  gap: 6px;
}

.selectedImage {
  border-radius: 4px;
  border: 4px solid var(--third-bg-color);
  background-color: var(--third-bg-color);
}

.selectedDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 4px;
}

.selectedTitle {
  font-size: 16px;

  font-weight: bold;

  text-overflow: ellipsis;
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
}

.selectedSubTitle {
  font-size: 12px;
}

.separator {
  width: 100%;
  height: 1px;

  background-image: linear-gradient(to right, var(--first-border-color) 50%, rgba(255, 0, 0, 0) 0%);
  background-position: top;
  background-size: 12px 1px;
  background-repeat: repeat-x;
}

.links {
  display: flex;
  flex-direction: column;
}

.link {
  display: flex;
  align-items: center;
  gap: 10px;

  padding: 12px 10px;
  text-decoration: none;
}

.elementImage {
  border-radius: 4px;
}

.elementTitle {
  font-size: 14px;

  color: var(--first-font-color);
}

.link:hover {
  background-color: var(--first-hover-color);
}

.opened {
  transition: .2s;
  transform: rotate(90deg);
}

.display {
  display: flex;
}
