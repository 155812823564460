.container {
    padding: 6px 10px 0 10px;

    display: flex;
    flex-direction: column;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.title {
    font-size: 20px;
    font-weight: bold;
    color: var(--first-font-color);
}

.subTitle {
    font-size: 16px;
    font-weight: bold;
    color: var(--first-font-color);
}

.activated {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}

.iconContainer {
    padding: 10px;
    border-radius: 4px;
    width: fit-content;
    display: flex;
    background-color: var(--header-button-color);
}

.name {
    font-size: 14px;
    color: var(--first-font-color);
}

.element.selected .iconContainer {
    background-color: var(--header-button-color-invert);
}

.element.selected .name {
    font-weight: bold;
}

.level {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.level.disabled .element {
    opacity: .4;
}
