.element {
    display: flex;
    gap: 20px;
    align-items: center;
    padding-left: 1px;

    position: relative;
}

.icon {
    height: 38px;
    width: 38px;
    background-color: #FFFFFF;
    border-radius: 100%;
    /* border: 1px solid var(--first-bg-color); */

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.iconMobile {
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.content {
    /*box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);*/

    padding: 4px 4px 4px 4px;
    flex-grow: 1;
    border-radius: 4px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;

    background-color: var(--header-button-color);

}

.content:hover {
}

.text, .right {
    display: flex;
    flex-direction: column;
}

.left {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding-left: 2px;
}

.text {
    gap: 4px;
    color: var(--first-font-color);
}

.dataLoader {
    margin: 0 auto;
    path {
        fill: var(--second-font-color);
    }
}

.thumbnail {
    image-rendering: optimizeQuality;
}

.right {
    max-width: 120px;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;

    text-align: center;
}

.title {
    font-size: 16px;
    font-weight: bold;
}

.description {
    font-size: 14px;
}

.description_02 {
    font-weight: bold;
    font-size: 12px;
}

.notReaded {
    height: 14px;
    width: 14px;

    background-color: var(--notification-color);

    position: absolute;
    top: -4px;
    right: -4px;

    border-radius: 100%;
}
