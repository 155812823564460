.container {
    padding: 6px 10px 0 10px;

    display: flex;
    flex-direction: column;
}

.content {

}

.title {
    font-size: 20px;
    font-weight: bold;
    color: var(--first-font-color);
}

.subTitle {
    font-size: 16px;
    font-weight: bold;
    color: var(--first-font-color);
}

.system {
    margin-top: 10px;
    display: flex;
    align-items: center;
    background-color: var(--header-button-color);
    justify-content: space-between;
    padding: 16px 10px;

    border-radius: 4px;
}

.left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.nameSystem {
    font-size: 16px;
    font-weight: bold;
    color: var(--first-font-color);
}

.nameOtherSystem {
    font-size: 14px;
    color: var(--first-font-color);
}

.checkbox {
    background-color: var(--bg-form-color);
    height: 30px;
    width: 30px;
    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.others {
    margin-top: 20px;
}

.contentOthers {

}

.otherSystem {
    margin-top: 10px;
    display: flex;
    align-items: center;
    background-color: var(--header-button-color);
    justify-content: space-between;
    padding: 10px 10px;
    border-radius: 4px;
}
