.contentContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    height: fit-content;

    overflow-y: scroll;
    overflow-x: hidden;
}

.timelapse {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 4px;
    background-color: var(--header-button-color);
    cursor: pointer;
}

.left {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.leftCenter {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.title {
    font-size: 18px;
    font-weight: bold;
}

.time {

}

.camera {

}

.favorite {
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: var(--second-bg-color);
}

.favorite:hover img {
    transform: scale(1.2);
    transition: transform .2s;
}

.expire, .created {
    font-style: italic;
    font-size: 14px;
    text-align: left;
}
