.element {
}

.range-slider {
    width: 100%;
    margin: 50px 0;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    background: var(--first-bg-color);
    outline: none;
    opacity: 1;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    padding: 0;
    margin: 16px 0;
    border-radius: 2px;
}

.emptyImages {
    font-style: italic;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: var(--first-bg-color);
    cursor: pointer;
    border: 2px solid white;
    border-radius: 50%;
}

.slider::-webkit-slider-thumb:hover {
    border: 2px solid white;
    background: var(--second-bg-color);
}

.slider::-webkit-slider-thumb:active {
    background: var(--second-bg-color);
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #F2F2F2;
    cursor: pointer;
    border: 2px solid white;
    border-radius: 50%;
}

.slider::-moz-range-thumb:hover {
    border: 2px solid white;
    background: var(--second-bg-color);
}

.slider::-moz-range-thumb:active {
    background: var(--second-bg-color);
}

.picture {
    max-width: 500px;
}

.pictureImg {
    max-width: 100%;
}
