.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-top: 20px;
}

.oldShare, .buttons {
    max-width: 600px;
    width: 100%;
}

.buttons {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.oldShare {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.oldShareText {
    display: flex;
    flex-direction: column;
    gap: 4px;
}


@media (max-width: 500px) {
    .oldShare {
        flex-direction: column;
        text-align: center;
    }

    .buttons {
        gap: 10px;
        flex-direction: column;
    }
}
