.navElement {

}

.navLink {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 38px;

  padding: 0px 10px;

  text-decoration: none;

  border-radius: 6px;
}

.navLink.active {
  background-color: var(--second-bg-color);
}

.navLink:hover {
  background-color: var(--second-bg-color);
}

.left {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  color: var(--first-font-color);
}

.count {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--second-bg-color);
  border-radius: 4px;

  padding: 4px 6px;
}

.countText {
  color: var(--first-font-color);
}

.arrow {
  transition: .2s;
}

.arrow.opened {
  transform: rotate(90deg);
}

.children {
  display: none;
}

.children.opened {
  display: flex;
}

.threadContainer {
  width: 38px;

  display: flex;
  justify-content: center;
}

.thread {
  height: 100%;
  width: 1px;
  background-image: linear-gradient(to bottom, var(--first-border-color) 50%, rgba(255, 0, 0, 0) 0%);
  background-position: top;
  background-size: 1px 6px;
  background-repeat: repeat-y;
}

.childrenContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;

  margin-top: 4px;
}

.childlink {
  color: var(--first-font-color);

  text-decoration: none;

  padding: 4px 6px;

  border-radius: 4px;
}

.childlink:hover {
  background-color: var(--second-bg-color);
}

.childlink.active {
  background-color: var(--second-bg-color);
}
