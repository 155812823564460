.authentication {
  height: 100dvh;
  width: 100%;
  background-color: var(--bg-form-container-color);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
}

.modal {
  padding: 30px 40px 30px 40px;
  border-radius: 12px;
  background-color: var(--bg-form-color);

  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.logo_bg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px
}

.logo {
  max-width: 270px;
  width: 100%;
}

.version {
  background-color: var(--bg-form-color);
  color: var(--first-font-color);

  border-radius: 4px;

  padding: 2px 4px;

  position: absolute;
  bottom: 10px;

  left: 50%;
  transform: translate(-50%, 0);

  font-size: 10px;
}

.errorMessage, .successMessage {
  text-align: center;

  font-size: 14px;

  bottom: 10px;
  width: 100%;

  margin-top: -6px;
  margin-bottom: -6px;

  max-width: 400px;
}

.errorMessage {
  color: var(--error-color);
}

.successMessage {
  color: var(--success-color);
}

@media (max-width: 500px) {
  .modal {
    border: 0;

    border-radius: 0;

    width: 100%;
  }

  .input {
    width: 100%;
    max-width: 100%;
  }
}
