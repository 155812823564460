
.alarm {
    display: flex;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    background-color: var(--header-button-color);
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.alarmText {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.alarmTitle {
    font-weight: bold;
    font-size: 16px;
    color: var(--first-font-color);
}

.alarmSubTitle {
    font-size: 16px;
    color: var(--first-font-color);
}

.cameras, .shortcuts, .indicators, .notifications {
    margin-top: 20px;
}

.title {
    font-size: 20px;
    font-weight: bold;
    color: var(--first-font-color);
    padding: 10px;
}

.subTitle {
    font-size: 20px;
    font-weight: bold;
    color: var(--first-font-color);
}

.camerasContainerDesktop {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    height: fit-content;
    margin-top: 10px;

    overflow-y: scroll;
    overflow-x: hidden;
}

.camerasContainer {
    max-width: 700px;
    width: 100%;
    overflow: hidden;
    margin: 10px auto 0 auto;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.camerasContent {
    display: flex;
    align-items: center;
    overflow: auto;
    list-style-type: none;
    border-radius: 4px;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.camerasContent::-webkit-scrollbar {
    display: none;
}

.cameraChild {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 100%;
    padding: 0 10px;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    -webkit-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
    min-height: 100px;
    position: relative;
}

.dataLoader {
    margin: 0 auto;
    path {
        fill: var(--second-font-color);
    }
}

.camera {
    transform: translateZ(0);
    width: 100%;
    flex: 0 0 100%;
    padding: 0 10px;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    -webkit-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
}

.cameraName {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--first-font-color);
}

.cameraNameSpan {
    padding: 4px 8px;
    border-radius: 4px;
    background-color: var(--header-button-color);
}

.camerasPoints {
    width: 100%;
    gap: 10px;
    display: flex;
    justify-content: center;
}

.point {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;

    cursor: pointer;
    -webkit-box-shadow:inset 0 0 0 1px var(--loader-color);
    -moz-box-shadow:inset 0 0 0 1px var(--loader-color);
    box-shadow:inset 0 0 0 1px var(--loader-color);
}

.point.active {
    background-color: var(--orange);
    -webkit-box-shadow:inset 0 0 0 1px var(--orange);
    -moz-box-shadow:inset 0 0 0 1px var(--orange);
    box-shadow:inset 0 0 0 1px var(--orange);
}

.hidden {
    display: none;
}

.shortcutsContainer {
    width: 100%;
    overflow: hidden;
    margin-top: 10px;
    padding: 10px 0;
    display: flex;
    gap: 20px;

    overflow-x: scroll;
}

.indicatorsContainer {
    width: 100%;
    overflow: hidden;
    margin-top: 10px;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.notificationsContainer {
    width: 100%;
    margin-top: 10px;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 14px;
}
