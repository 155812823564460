.navigationBar {
    position: sticky;
    bottom: 0;

    width: 100%;

    z-index: 0;

    background-color: var(--header-color);

    height: fit-content;

    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
}

.navigation {
    height: 35px;
    width: 35px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.icon {

}

.text {
    font-size: 10px;
    color: var(--first-font-color);
}

.selected .text {
    font-weight: bold;
}
