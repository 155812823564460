.toastBar {
    position: absolute;
    top: 34px;
    right: 50px;
    z-index: 500;
    /* padding: 20px; */

    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: end;

    max-height: 80%;
    overflow: hidden;
    overflow-y: auto;
}

@media (max-width: 1224px) {
    .toastBar {
        overflow: visible;
        top: 50px;
        margin: 20px;
        right: 0;
    }
}
