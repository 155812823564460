.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-top: 20px;
}

.input {
    width: 400px;
    height: 40px;

    background-color: var(--first-bg-input-color);
    border: 0;

    border-radius: 4px;

    padding: 4px 8px;

    color: var(--second-font-color);
    font-size: 16px;
}

.input:focus {
    outline: none;
}

@media (max-width: 500px) {
    .input {
        width: 100%;
        max-width: 100%;
    }
}

.buttons {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
