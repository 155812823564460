.form {
  display: flex;
  flex-direction: column;

  gap: 4px
}

.label {
  font-size: 14px;

  color: var(--first-font-color);
}

.input {
  width: 400px;
  height: 40px;

  background-color: var(--header-button-color);
  border: 0;

  border-radius: 4px;

  padding: 4px 8px;

  color: var(--first-font-color);
  font-size: 16px;
}

.input:focus {
  outline: none;
}

@media (max-width: 500px) {
  .input {
    width: 100%;
    max-width: 100%;
  }
}
