.draggableContainer {
  opacity: 1;

  border-radius: 4px;
  overflow: hidden;
  height: fit-content;
}

.gridSize-99 {
  min-width: 120px;
  max-width: 160px;
  width: 100%;
  padding: 4px 0;
}

.gridSize-1 {
  width: 7%;
}

.gridSize-2 {
  width: 15%;
}

.gridSize-3 {
  width: 23%;
}

.gridSize-4 {
  width: 30%;
}

.gridSize-5 {
  width: 39%;
}

.gridSize-6 {
  width: 48%;
}

.gridSize-7 {
  width: 56%;
}

.gridSize-8 {
  width: 64%;
}

.gridSize-9 {
  width: 75%;
}

.gridSize-10 {
  width: 81%;
}

.gridSize-11 {
  width: 90%;
}

.gridSize-12 {
  width: 98%;
}

@media (max-width: 1224px) {

  .gridSize-1 {
    width: 15%;
  }

  .gridSize-2 {
    width: 30%;
  }

  .gridSize-3 {
    width: 48%;
  }

  .gridSize-4 {
    width: 64%;
  }

  .gridSize-5 {
    width: 81%;
  }

  .gridSize-6 {
    width: 98%;
  }

  .gridSize-7 {
    width: 98%;
  }

  .gridSize-8 {
    width: 98%;
  }

  .gridSize-9 {
    width: 98%;
  }

  .gridSize-10 {
    width: 98%;
  }

  .gridSize-11 {
    width: 98%;
  }

  .gridSize-12 {
    width: 98%;
  }
}
