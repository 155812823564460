.container {
    padding: 6px 10px 0 10px;

    display: flex;
    flex-direction: column;
}

.content {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 6px;
    overflow-x: scroll;
}

.title {
    font-size: 20px;
    font-weight: bold;
    color: var(--first-font-color);
}
