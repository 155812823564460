.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: var(--header-color);
    position: sticky;
    top: 0;
}

.logo {
    max-width: 150px;
    width: 100%;
}

.actions {
    display: flex;
    gap: 10px;
}

.btnAction {
    background-color: var(--header-button-color);
    height: 36px;
    width: 36px;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}

.notifications {
    position: relative;
}

.notificationsCount {
    height: 18px;
    width: 18px;
    top: -6px;
    right: -6px;
    background-color: var(--important-color);
    position: absolute;
    border-radius: 100%;

    border: 1px solid var(--header-button-color);

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--first-font-button-color);

    font-size: 9px;
    font-weight: bold;

}

@media (max-width: 350px) {
    .logo {
        max-width: 100px;
    }
}
