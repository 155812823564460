.element {
    display: flex;
    position: relative;
    width: 100%;
}

.line {
    height: 100%;
    width: 2px;
    background-image: linear-gradient(to bottom, var(--header-button-color) 50%, rgba(255, 0, 0, 0) 0%);
    background-position: top;
    background-size: 2px 12px;
    background-repeat: repeat-y;
    padding: 0px 20px;

    position: absolute;
    z-index: 0;
}

.container {
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
}

.title {
    display: flex;
    gap: 20px;
    align-items: center;
}

.date {
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
    color: var(--first-font-color);
}

.content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.icon {
    height: 40px;
    width: 40px;
    background-color: var(--header-button-color);
    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;
}
