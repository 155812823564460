.toastElement {
    padding-left: 3px;
    border-radius: 4px 5px 5px 4px;

    -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
    -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 3px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;

    user-select: none;

    cursor: pointer;
}

.toastElement.information {
    background-color: var(--toast-information-color);
}

.toastElement.information .loadingBar {
    background-color: var(--toast-information-color);
}

.toastElement.success {
    background-color: var(--toast-success-color);
}

.toastElement.success .loadingBar {
    background-color: var(--toast-success-color);
}

.toastElement.error {
    background-color: var(--toast-error-color);
}

.toastElement.error .loadingBar {
    background-color: var(--toast-error-color);
}

.toastElement.warning {
    background-color: var(--toast-warning-color);
}

.toastElement.warning .loadingBar {
    background-color: var(--toast-warning-color);
}

/*.toastElement:hover .loadingBar {*/
/*    width: 100% !important;*/
/*}*/

.content {
    background-color: white;

    max-width: 360px;
    width: fit-content;

    border-radius: 4px;
    padding: 14px 12px 14px 12px;

    position: relative;

    overflow: hidden;
}

.header {
    display: flex;
    align-items: center;
}

.icon {
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.message {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-left: 12px;
    margin-right: 30px;
    color: var(--black-codnum);
}

.title {
    font-size: 14px;
    font-weight: bold;
}

.description {
    font-size: 12px;
}

.close {
    height: 30px;
    width: 30px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    cursor: pointer;
}

.close:hover {
    background-color: var(--first-hover-color);
}

.data {
    padding: 20px 10px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.dataImage {
    border-radius: 4px;
    width: 100%;
}

.dataLoader {
    margin: 0 auto;
    path {
        fill: var(--second-font-color);
    }
}

.loadingBar {
    position: absolute;
    opacity: 0.75;
    height: 3px;
    width: 100%;
    bottom: 0;
    left: 0;
}

.timeElapsed {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    text-align: left;
    padding: 2px 4px;
}

@media (max-width: 1224px) {
    .toastElement {
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    }

    .content {
        display: flex;
    }

    .message {
        margin-right: 5px;
    }

    .close {
        display: none;
    }

    .data {
        padding: 5px;
    }

    .dataImage {
        max-width: 160px;
    }
}
