.element {
  background-color: var(--second-bg-color);

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 5px;

  border-radius: 4px;

  cursor: pointer;

  position: relative;
}

.element02 {
  background-color: var(--header-button-color);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 10px;

  gap: 6px;

  border-radius: 4px;

  cursor: pointer;

  min-width: 180px;
  min-height: 140px;
}

.nameContainer {
  display: none;

  justify-content: center;
  align-items: center;

  padding: 4px 8px;

  position: absolute;

  right: 40px;

  background-color: var(--second-bg-color);

  border-radius: 4px;

  max-width: 300px;
  width: max-content;

  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.element:hover .nameContainer {
  display: flex;
}

.name {
  text-align: center;
  color: var(--first-font-color);
  z-index: 100;
}

.name02 {
  color: var(--first-font-color);
  font-weight: 600;
}

.status {
  color: var(--first-font-color);
}
