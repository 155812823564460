@import url('https://fonts.googleapis.com/css?family=Inter:100,200,300,400,600,700,800,900&display=swap');

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  --white: #FFFFFF;
  --grey: #F3F3F3;

  --black: #000000;
  --black-codnum: #0A0908;

  --orange: #E09C29;

  user-select: none;
}

.light-mode {
  --first-bg-color: var(--grey);
  --second-bg-color: var(--white);
  --third-bg-color: var(--white);
  --fourth-bg-color: #9F9F9F22;

  --first-hover-color: #9F9F9F22;

  --first-border-color: #9F9F9F;

  --first-font-color: #0A0908;
  --second-font-color: #0A0908;

  --first-button-color: #3E8CEE;

  --toast-information-color: #387ADF;
  --toast-success-color: #2ECC71;
  --toast-error-color: #B22222;
  --toast-warning-color: #FBA834;

  --error-color: #B22222;
  --success-color: #2ECC71;

  --notification-color: #387ADF;

  /* New inputs */
  --first-bg-input-color: var(--grey);
  --first-font-button-color: var(--white);
  --bg-form-container-color: var(--grey);
  --bg-form-color: var(--white);

  --loader-color: #0A0908;

  --important-color: #E72929;

  --header-color: var(--white);
  --header-button-color: var(--grey);
  --header-button-color-invert: #0A0908;
}

.dark-mode {
  --first-bg-color: #303030;
  --second-bg-color: #0A0908;
  --third-bg-color: var(--white);
  --fourth-bg-color: #9F9F9F22;

  --first-hover-color: #9F9F9F22;

  --first-border-color: #9F9F9F;

  --first-font-color: var(--white);
  --second-font-color: #0A0908;

  --first-button-color: #3E8CEE;

  --toast-information-color: #387ADF;
  --toast-success-color: #2ECC71;
  --toast-error-color: #B22222;
  --toast-warning-color: #FBA834;

  --error-color: #B22222;
  --success-color: #2ECC71;

  --notification-color: #387ADF;

  /* New inputs */
  --first-bg-input-color: #9F9F9F;
  --first-font-button-color: var(--white);
  --bg-form-container-color: #0A0908;
  --bg-form-color: #0A0908;

  --loader-color: var(--white);

  --important-color: #E72929;

  --header-color: #0A0908;
  --header-button-color: #303030;
  --header-button-color-invert: var(--white);
}

.App {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100dvh;
}

@media (max-width: 1224px) {
  .content {
    overflow: hidden;
  }

  .content {
    border-radius: 0;
  }
}

.content {
  overflow-y: auto;
  height: 100%;
  width: 100%;
  display: flex;
}

.loader {
  margin: 0 auto;
  path {
    fill: var(--loader-color);
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  color: var(--first-font-color);
}

.btn-01 {
  padding: 8px 24px;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  background-color: var(--orange);
  color: var(--white);
  cursor: pointer;
  font-weight: 500;
}

.btn-01:active {
  transform: scale(1.08);
}

.btn-01:disabled {
  transform: scale(1);
  cursor: not-allowed;
  background-color: var(--header-button-color);
  color: var(--first-font-color);
}


.btn-02 {
  padding: 8px 24px;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  background-color: var(--header-button-color);
  color: var(--first-font-color);
  cursor: pointer;
}

.btn-02:active {
  transform: scale(1.08);
}

.btn-02:disabled {
  transform: scale(1);
  cursor: not-allowed;
  background-color: var(--header-button-color);
  color: var(--first-font-color);
}
