.container {
    padding: 6px 10px 0 10px;

    display: flex;
    flex-direction: column;
}

.content {

}

.title {
    font-size: 20px;
    font-weight: bold;
    color: var(--first-font-color);
}

.subTitle {
    font-size: 16px;
    font-weight: bold;
    color: var(--first-font-color);
}

.nav {
    display: flex;

    justify-content: space-between;
    margin-top: 6px;
    gap: 20px;
}

.navElement {
    padding: 6px 10px;
    border-radius: 4px;
    background-color: var(--header-button-color);

    display: flex;
    flex-direction: column;
    gap: 8px;

    max-width: 250px;
    width: 100%;
    color: var(--first-font-color);
}

.navImage {
    height: 30px;
    width: 30px;
}

.help {
    margin-top: 20px;
}

.helpContent {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.helpElement {
    background-color: var(--header-button-color);    border-radius: 4px;
    padding: 10px;

    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--first-font-color);
}
