.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-top: 20px;
}

.inputs, .buttons {
    max-width: 400px;
    width: 100%;
}

.inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.buttons {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.request {
    text-align: left;
}

.logo_bg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px
}

.error {
    color: var(--error-color);
}

.logo {
    max-width: 270px;
    width: 100%;
}
