.container {
    padding: 26px 10px 0 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 20px;
}

.title {
    font-size: 20px;
    font-weight: bold;
    color: var(--first-font-color);
}

.see {
    padding: 8px 24px;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    background-color: var(--orange);
    color: var(--white);
    cursor: pointer;
    font-weight: 500;
}

.see:active {
    transform: scale(1.08);
}
