.container {
    padding: 6px 10px 0 10px;

    display: flex;
    flex-direction: column;
}

.content {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 6px;
    overflow-x: scroll;
}

.title {
    font-size: 20px;
    font-weight: bold;
    color: var(--first-font-color);
}

.cameras {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.camerasSelector {
    display: flex;
    padding: 10px 0;
    gap: 0;
    overflow-x: scroll;
    width: 100%;
    justify-content: center;
}

.dates {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    align-items: center;

    margin-top: 10px;
}

.datesText, .text {
    color: var(--first-font-color);
}

.generateContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

@media (max-width: 1224px) {
    .camerasSelector {
        justify-content: flex-start;
    }
}
