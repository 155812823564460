.button {
  padding: 14px 36px;
  width: fit-content;
  margin: 0 auto;

  border-radius: 4px;
  border: 0;

  cursor: pointer;

  background-color: var(--first-button-color);
  color: var(--first-font-button-color);

  font-size: 16px;
}

.button:active {
  transform: scale(.96);

}

.button:disabled {
  background-color: var(--first-border-color);
  cursor: auto;
  transform: scale(1);
}
