.nav {
  color: var(--first-font-color);

  max-width: 280px;
  width: 100%;

  padding: 24px 0px;

  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.links {
  width: 100%;

  list-style-type: none;
  margin: 0;

  padding: 30px 20px;

  display: flex;

  flex-direction: column;
  gap: 20px;

  flex-grow: 1;
}

.footer {
  display: flex;
}

.version {
  position: absolute;
  bottom: 4px;

  background-color: var(--bg-form-color);
  color: var(--first-font-color);

  border-radius: 4px;

  padding: 2px 4px;

  left: 50%;
  transform: translate(-50%, 0);

  font-size: 10px;
}

.icon {
  path {
    stroke: red;
  }
}

.icon:hover {
  path {
    stroke: yellow;
    fill: blue;
  }
}
