.dashboard {
  height: 100dvh;
  width: 100%;
  background-color: var(--first-bg-color);

  display: flex;
}

.dashboardContainer {
  width: 100%;
  display: flex;
  margin-top: 24px;
}

.content {
  background-color: var(--header-color);

  border-radius: 12px 12px 0px 0px;
  height: 100%;
  width: 100%;

  overflow: hidden;

  display: flex;
  flex-direction: column;
}

@media (max-width: 1224px) {
  .dashboardContainer {
    margin-top: 0;
    flex-grow: 1;
    overflow: scroll;
  }

  .content {
    border-radius: 0;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 12px;

  width: 100%;

  border-bottom: 1px solid var(--first-border-color);
}

.left {

  display: flex;
  gap: 10px;
}

.iconContainer {

  background-color: var(--first-bg-color);
  border-radius: 4px;

  height: 40px;
  width: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.title {
  font-size: 15px;
  font-weight: bold;
  color: var(--first-font-color);

  overflow-wrap: anywhere;
}

.subTitle {
 font-size: 13px;
  color: var(--first-font-color);
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.btnAction {
  background-color: var(--header-button-color);
  height: 36px;
  width: 36px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
}

.btnAction:hover img{
  transform: scale(1.2);
}

.contentContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  height: fit-content;

  overflow-y: scroll;
  overflow-x: hidden;
}

.contentContainerY {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 10px;
  height: fit-content;

  overflow-y: scroll;
  overflow-x: hidden;
}

.contentContainer01 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  height: fit-content;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1224px) {
  .dashboard {
    background-color: #FFFFFF;

    flex-direction: column;
  }
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  height: fit-content;
  overflow-y: scroll;
  overflow-x: hidden;
}
