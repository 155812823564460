.background {
    height: 100%;
    width: 100%;


    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 600;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    justify-content: flex-end;
    overflow: hidden;
    flex-direction: column;
}

.element {
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    position: relative;
    padding-bottom: 30px;
    background-color: var(--header-color);
}

.element.hide {
    transform: translateY(100%);
}

.line {
    width: 100%;
    height: 5px;
    border-radius: 4px;

    background-color: var(--loader-color);
}

.head {
    max-width: 120px;
    width: 100%;
    padding: 6px 6px 0 6px;

    margin: 2px auto;
}

.close {
    position: absolute;
    right: 10px;
    top: 10px;

    height: 26px;
    width: 26px;

    background-color: var(--grey);

    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.closeIcon {
    transform: rotate(45deg);
}

.container {
    padding: 6px 10px 0 10px;

    display: flex;
    flex-direction: column;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 6px;
}

.title {
    font-size: 20px;
    font-weight: bold;
    color: var(--first-font-color);
    margin-right: 30px;
}
