.signIn {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.lostPasswordContainer {
  text-align: right;
}

.lostPassword {
  text-align: right;
  color: var(--first-font-color);
  font-size: 14px;
  width: fit-content;
}