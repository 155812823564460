.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;

    height: 100dvh;
    width: 100%;
    background-color: var(--header-color);
}

.logo_bg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px
}

.logo {
    max-width: 270px;
    width: 100%;
}
